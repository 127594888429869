<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">

            <!-- Login v1 -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <img :src="logo" alt="Logo" class="img-fluid" width="85%">
                </b-link>

                <b-card-title class="mb-1">Bienvenido(a) a <span class="text-primary text-bold">Inmobiliaria RPI - Admin</span>! 👋</b-card-title>
                <b-card-text class="mb-2">¡Inicie sesión y comience hacer uso de los diferentes módulos que ofrece la plataforma!</b-card-text>

                <validation-observer ref="loginForm">
                    
                    <b-form class="auth-login-form mt-2">

                        <!-- EMAIL -->
                        <div class="mb-2">
                            <label for="email"><strong>CORREO ELECTRÓNICO</strong></label>
                            <validation-provider
                                #default="{ errors }"
                                name="email"
                                rules="required|email"
                            >
                                <b-form-input
                                    id="email"
                                    v-model="userEmail"
                                    name="login-email"
                                    autocomplete="login-email"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="john@example.com"
                                    autofocus
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </div>

                        <!-- PASSWORD -->
                        <div class="mb-2">
                            <label for="password"><strong>CONTRASEÑA</strong></label>
                            <validation-provider
                                #default="{ errors }"
                                name="contraseña"
                                rules="required"
                            > 
                                <b-input-group
                                    class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : null"
                                >
                                    <b-form-input
                                        id="password"
                                        v-model="password"
                                        :type="passwordFieldType"
                                        class="form-control-merge"
                                        :state="errors.length > 0 ? false : null"
                                        name="login-password"
                                        autocomplete="current-password"
                                        placeholder="Password"
                                    />

                                    <b-input-group-append is-text>
                                        <feather-icon
                                            class="cursor-pointer"
                                            :icon="passwordToggleIcon"
                                            @click="togglePasswordVisibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </div>

                        <!-- BUTTON SUBMIT -->
                        <b-button-loading 
                            text="INICIAR SESIÓN" 
                            :processing="processing"
                            :block="true"
                            @process-action="login">
                        </b-button-loading>

                    </b-form>

                </validation-observer>

            </b-card>
            <!-- /Login v1 -->

        </div>
    </div>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { togglePasswordVisibility } from '@core/mixins/ui/forms';
    import { getHomeRouteForLoggedInUser } from '@/auth/utils';
    import { required, email } from '@validations';
    import useJwt from '@/auth/jwt/useJwt';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            BButtonLoading
        },
        mixins: [ togglePasswordVisibility ],
        data() {
            return {
                logo: require('@/assets/images/logo/logo.svg'),
                processing: false,

                userEmail: '',
                password: '',
                required,
                email
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            }
        },
        methods: {
            login() {
                this.$refs.loginForm.validate().then(success => {
                    if (success) {

                        this.processing = true;

                        useJwt.login({
                            email: this.userEmail,
                            password: this.password
                        })
                        .then(response => {
                            if (response.data.success) {
                                
                                const { userData, accessToken, refreshToken } = response.data;
    
                                useJwt.setToken(accessToken);
                                useJwt.setRefreshToken(refreshToken);

                                localStorage.setItem('userData', JSON.stringify(userData));

                                this.$ability.update(userData.ability);
    
                                this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                                    .then(() => {
                                        this.$toast({
                                            component: ToastificationContent,
                                            position: 'top-right',
                                            props: {
                                                title: `Bienvenido(a) ${userData.fullName}`,
                                                icon: 'CoffeeIcon',
                                                variant: 'success',
                                                text: `Ha iniciado sesión correctamente como ${userData.roleAlias}. ¡Ahora puedes empezar a explorar la plataforma!`,
                                            }
                                        })
                                    });
                            }
                        })
                        .catch(error => this.$refs.loginForm.setErrors(error.response.data.error))
                        .finally( () => this.processing = false);
                    
                    }
                });
            }
        }
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
